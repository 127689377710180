<template>
  <div class="departments">
    <ul>
      <li v-for="department in departments" :key="department.id">
        <input
          v-model="isSelected"
          name="department-selection"
          :id="'box-' + department.id"
          type="radio"
          :value="'box-' + department.id"
        />
        <label :for="'box-' + department.id">
          <div class="_box" :class="{ active: isSelected == 'box-' + department.id }">
            <div class="image">
              <img :src="department.image" alt="department-image" />
            </div>
            <div class="name">
              <h3 class="primary">{{ department.name }}</h3>
            </div>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/selection/selection-boxes.less"></style>

<script>
export default {
  name: 'Communities',
  data() {
    return {
      departments: [
        {
          id: 1,
          name: 'Los Angeles Police Dep',
          image: 'https://forum.fivem.net/uploads/default/original/3X/4/2/429efb3c8d94836dca14be05101110f2df7378ba.png',
          membersOnline: 103
        },
        {
          id: 2,
          name: 'Chicago Police Dep',
          image: 'https://forum.fivem.net/uploads/default/original/3X/0/5/053b6df4ff9cf7286598c7c0a7322ea9b6d7e93a.png',
          membersOnline: 44
        },
        {
          id: 3,
          name: 'Elon Musk Alien Department',
          image:
            'https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cg_face%2Cq_auto:good%2Cw_300/MTE1ODA0OTcxOTUyMDE0ODYx/elon-musk-20837159-1-402.png',
          membersOnline: 434
        },
        {
          id: 4,
          name: 'Toast Immigration United States Department',
          image: 'http://4.bp.blogspot.com/_-R-KkHig0Ag/S9jXkIahGjI/AAAAAAAAAGg/FRecohqw9Qc/s1600/story21c.jpg',
          membersOnline: 4
        }
      ],
      isSelected: ''
    }
  }
}
</script>
